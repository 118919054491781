<div class="notification-grid">
  <ng-container *ngFor="let notification of notifications; let i = index">
    <app-success-notification 
      *ngIf="notification.type === 'success'" 
      [title]="notification.title" 
      [message]="notification.message" 
      (close)="removeNotification(i)">
    </app-success-notification>

    <app-fail-notification 
      *ngIf="notification.type === 'fail'" 
      [title]="notification.title" 
      [message]="notification.message" 
      (close)="removeNotification(i)">
    </app-fail-notification>

    <app-warning-notification 
      *ngIf="notification.type === 'warn'" 
      [title]="notification.title" 
      [message]="notification.message" 
      (close)="removeNotification(i)">
    </app-warning-notification>

    <app-info-notification 
      *ngIf="notification.type === 'info'" 
      [title]="notification.title" 
      [message]="notification.message" 
      (close)="removeNotification(i)">
    </app-info-notification>
  </ng-container>
</div>
