import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface Notification {
  type: "success" | "fail" | "warn" | "info";
  title: string;
  message: string;
}

@Injectable({
  providedIn: "root", 
})
export class NotificationService {
  private notificationsSubject = new BehaviorSubject<Notification[]>([]);
  notifications$ = this.notificationsSubject.asObservable();

  constructor() {}

  addNotification(type: "success" | "fail" | "warn" | "info", title: string, message: string) {
    console.log("called add notif")
    const notifications = this.notificationsSubject.getValue();
    this.notificationsSubject.next([...notifications, { type, title, message }]);
    console.log()
    // Auto-remove notification after 5 seconds
    setTimeout(() => this.removeNotification(0), 6200);
  }

  removeNotification(index: number) {
    const notifications = this.notificationsSubject.getValue();
    notifications.splice(index, 1);
    this.notificationsSubject.next([...notifications]);
  }
}
