import { Component } from "@angular/core";
import { NotificationService } from "src/app/theme/shared/notification/notification.service";
import { Notification } from "./notification";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent {
  notifications: Notification[] = [];

  constructor(private notificationService: NotificationService) {
    this.notificationService.notifications$.subscribe((notifications) => {
      this.notifications = notifications;
    });
  }

  removeNotification(index: number) {
    this.notificationService.removeNotification(index);
  }
}
