import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-info-notification",
  templateUrl: "./info-notification.component.html",
  styleUrls: ["./info-notification.component.scss"],
})
export class InfoNotificationComponent implements OnInit {

  @Input() title: string = "Opération réussie"
  @Input() message: string = "Opération effectuée avec succès"
  @Output() close = new EventEmitter<void>();

  constructor(private router: Router, private translate: TranslateService) { }

  ngOnInit(): void { };
  hide() {
    this.close.emit();
  }
}
